import React, { useState } from "react";
import { H2, H3 } from "app/components/Typography";
import Button from "app/components/Button";
import Flex from "app/components/Flex";
import { ReactSVG } from "react-svg";
import { motion } from "framer-motion";
import { ScreenGtMd, ScreenLtMd } from "app/components/MediaQueries";
import { ChallengeProgressCard } from "app/components/ChallengeProgressCard";
import { ChallengeProgressResult } from "services/graphql";
import styles from "./styles.module.scss";

interface ChallengeProgressPromptProps {
  challengeCalculation: ChallengeProgressResult;
  onContinue: () => void;
}

export function ChallengeProgressPrompt({
  challengeCalculation,
  onContinue,
}: ChallengeProgressPromptProps) {
  const [showEarnedBadge, setShowEarnedBadge] = useState(
    challengeCalculation?.newlyEarnedBadges?.length > 0
  );
  const [showChallengeProgress, setShowChallengeProgress] = useState(
    !showEarnedBadge
  );

  const handleContinue = () => {
    if (showEarnedBadge) {
      setShowEarnedBadge(false);
      setShowChallengeProgress(true);
    } else {
      onContinue();
    }
  };

  const newlyEarnedBadge =
    challengeCalculation?.newlyEarnedBadges?.[
      challengeCalculation.newlyEarnedBadges.length - 1
    ]?.badge;

  const challengeProgressed = challengeCalculation?.challengesProgressed?.find(
    (challengeProgress: any) =>
      challengeProgress.challenge.id ===
      newlyEarnedBadge?.achievement?.challenge?.id
  );

  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={3}
    >
      {showEarnedBadge && (
        <>
          <ScreenGtMd>
            <H2 color="white" textAlign="center" mb={4}>
              You earned a badge!
            </H2>
          </ScreenGtMd>
          <ScreenLtMd>
            <H3 color="white" textAlign="center" mb={3}>
              You earned a badge!
            </H3>
          </ScreenLtMd>
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ ease: "easeOut", duration: 0.3, delay: 0.2 }}
          >
            <ReactSVG
              className={styles.badgeImage}
              src={newlyEarnedBadge?.activeImageUrl}
              beforeInjection={svg => {
                svg.setAttribute(
                  "aria-label",
                  newlyEarnedBadge?.name || "Achievement badge"
                );
              }}
            />
          </motion.div>
          <H3 color="white" textAlign="center" mt={3}>
            {newlyEarnedBadge?.name} from {challengeProgressed?.challenge.title}
          </H3>
        </>
      )}

      {showChallengeProgress && !showEarnedBadge && (
        <>
          <ScreenGtMd>
            <H2 color="white" textAlign="center" mb={4}>
              You made progress on this challenge!
            </H2>
          </ScreenGtMd>
          <ScreenLtMd>
            <H3 color="white" textAlign="center" mb={3}>
              You made progress on this challenge!
            </H3>
          </ScreenLtMd>
          {challengeCalculation?.challengesProgressed?.map(
            challengeProgress => (
              <motion.div
                key={challengeProgress.challenge.id}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ ease: "easeOut", duration: 0.3, delay: 0.2 }}
                style={{ width: "100%", maxWidth: "480px" }}
              >
                <ChallengeProgressCard
                  challenge={challengeProgress.challenge}
                />
              </motion.div>
            )
          )}
        </>
      )}

      <Flex flexDirection="column" mt={4}>
        <Button onClick={handleContinue}>Continue</Button>
      </Flex>
    </Flex>
  );
}
