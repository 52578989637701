// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__container--b2Ond{display:flex;flex-direction:column;padding:0 16px;width:100%}.styles-module__container--b2Ond p{margin:12px 0}.styles-module__container--b2Ond p strong{text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/app/components/SubscribeModal/ReactivateSubscriptionFlow/styles.module.scss"],"names":[],"mappings":"AACA,iCACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,UAAA,CAEA,mCACE,aAAA,CAEA,0CACE,yBAAA","sourcesContent":["@use \"@/styles/_variables\" as *;\n.container {\n  display: flex;\n  flex-direction: column;\n  padding: 0 16px;\n  width: 100%;\n\n  p {\n    margin: 12px 0;\n\n    strong {\n      text-transform: capitalize;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module__container--b2Ond"
};
export default ___CSS_LOADER_EXPORT___;
