import React, { useState, useEffect } from "react";
import Flex from "app/components/Flex";
import Ratings from "app/components/Ratings";
import Lottie from "react-lottie-light";
import { P1, H3 } from "app/components/Typography";
import { useDispatch, useSelector } from "react-redux";
import { saveClassRatingAction } from "modules/user/actions";
import { motion } from "framer-motion";
import endClassAnimationData from "./lottie-end-class.json";

interface CompletionOverlayProps {
  classId: number;
  children?: React.ReactNode;
  closeOverlay: () => void;
  isClassOverlayTimerStopped?: boolean;
}

interface RootState {
  user: {
    classRatings?: {
      [key: number]: {
        rating: number;
      };
    };
  };
}

export const CompletionOverlay: React.FC<CompletionOverlayProps> = ({
  classId,
  children = null,
  closeOverlay,
  isClassOverlayTimerStopped = false,
}) => {
  const dispatch = useDispatch();
  const [showDetails, toggleDetails] = useState<boolean>(false);
  const [showChildren, toggleChildren] = useState<boolean>(false);
  const classRating = useSelector(
    (state: RootState) => state.user?.classRatings?.[classId]?.rating
  );

  useEffect(() => {
    if (isClassOverlayTimerStopped) {
      toggleDetails(true);
      toggleChildren(true);
    }
    return () => {
      toggleDetails(false);
      toggleChildren(false);
    };
  }, [isClassOverlayTimerStopped]);

  return (
    <Flex
      top={0}
      position="absolute"
      bg="rgb(0, 0, 0)"
      width="100%"
      height="100%"
      /* Needs to be under Show Sections and ControlBar */
      zIndex={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Flex
        maxHeight={{ _: "300px", md: "500px" }}
        py={2}
        width="100%"
        position="relative"
        flexDirection="column"
      >
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          opacity={showDetails ? 1 : 0}
          transition="opacity 150ms ease-in"
        >
          <H3 color="white">Enjoy the class? Give us a rating!</H3>
          <Ratings
            my={3}
            rating={classRating}
            onRate={(updatedRating: number) =>
              dispatch(
                saveClassRatingAction({
                  rating: updatedRating,
                  classId,
                })
              )
            }
          />
        </Flex>
        {showChildren ? (
          <motion.div
            initial={{ y: 15, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeIn", duration: 0.2 }}
          >
            <Flex
              width="100%"
              mt={2}
              justifyContent="center"
              alignItems="center"
            >
              {children}
            </Flex>
          </motion.div>
        ) : (
          <>
            <Flex
              position="relative"
              flexDirection="column"
              width="100%"
              height="250px"
              justifyContent="center"
              alignItems="center"
            >
              <Flex position="absolute" width="100%" zIndex="-1">
                <Lottie
                  height="400px"
                  options={{
                    autoplay: true,
                    loop: false,
                    animationData: endClassAnimationData,
                  }}
                  eventListeners={[
                    {
                      eventName: "complete",
                      callback: () => {
                        toggleDetails(true);
                        if (children) {
                          toggleChildren(true);
                        }
                      },
                    },
                  ]}
                />
              </Flex>
            </Flex>
          </>
        )}
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          opacity={showDetails ? 1 : 0}
          transition="opacity 150ms ease-in"
        >
          <P1
            cursor="pointer"
            color="white"
            mt={{ _: 1, md: 2 }}
            onClick={closeOverlay}
          >
            Back to Class
          </P1>
        </Flex>
      </Flex>
    </Flex>
  );
};
